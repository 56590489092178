$BRAND: #000000;
$BRAND_TRANSPARENT_01: #00000011;
$BRAND_TRANSPARENT_02: #00000022;
$BRAND_TRANSPARENT_03: #00000033;
$BRAND_TRANSPARENT_04: #00000044;
$BRAND_TRANSPARENT_05: #00000055;
$BRAND_TRANSPARENT_06: #00000066;
$BRAND_TRANSPARENT_07: #00000077;
$BRAND_TRANSPARENT_08: #00000088;
$BRAND_TRANSPARENT_09: #00000099;
$BRAND_TRANSPARENT_10: #000000AA;
$BRAND_TRANSPARENT_11: #000000BB;
$BRAND_TRANSPARENT_12: #000000CC;
$BRAND_TRANSPARENT_13: #000000DD;
$BRAND_TRANSPARENT_14: #000000EE;
$BRAND_LIGHT: #FCF4F5;
$ACCENT: #27AE60;
$SAND: #F9F8F5;
$SAND_DARKER: #F9F9F9;
$ERROR_RED: #DB1E32;
$FACEBOOK: #3b5998;
$FACEBOOK_LIGHT: #3a61b3;
$APPLE: #000;
$APPLE_LIGHT: #5C5C5C;
$GOOGLE: #FFF;
$GOOGLE_LIGHT: #F3F3F3;

//Greyscale
$BLACK: #000000;
$GREY_DARK: #4F4F4F;
$GREY_MEDIUM_DARK: #5F5F5F;
$GREY_MEDIUM: #949494;
$GREY_MEDIUM_LIGHT: #BDBDBD;
$GREY_LIGHT: #C9C9C9;
$GREY_EXTRA_LIGHT: #EEEEEE;
$GREY_SUPER_LIGHT: #FAFAFA;
$WHITE: #FFFFFF;

@import "siteColors";
