$BRAND: #006279;
$BRAND_TRANSPARENT_01: #00627911;
$BRAND_TRANSPARENT_02: #00627922;
$BRAND_TRANSPARENT_03: #00627933;
$BRAND_TRANSPARENT_04: #00627944;
$BRAND_TRANSPARENT_05: #00627955;
$BRAND_TRANSPARENT_06: #00627966;
$BRAND_TRANSPARENT_07: #00627977;
$BRAND_TRANSPARENT_08: #00627988;
$BRAND_TRANSPARENT_09: #00627999;
$BRAND_TRANSPARENT_10: #006279AA;
$BRAND_TRANSPARENT_11: #006279BB;
$BRAND_TRANSPARENT_12: #006279CC;
$BRAND_TRANSPARENT_13: #006279DD;
$BRAND_TRANSPARENT_14: #006279EE;
