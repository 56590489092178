@import "styles/colors";
@import "styles/breakpoints";

.container {
  background: $GREY_SUPER_LIGHT;

  .header {
    position: relative;
    padding: 45px 0 25px;
    color: $GREY_DARK;
    font-size: 1.4rem;
    line-height: 40px;
    text-align: center;

    @media only screen and (min-width: $TABLET) {
      padding-bottom: 45px;
    }

    .back {
      position: absolute;
      top: 5px;
      left: 30px;
      font-size: 1.2rem;
      color: $BRAND;
      @media only screen and (min-width: $TABLET) {
        top: unset;
      }
    }

    h1 {
      text-transform: uppercase;
    }

    .description {
      max-width: 800px;
      margin: auto;
      padding: 0 30px;
      line-height: initial;
      p {
        margin: 30px 0 0;
      }
    }
  }

  .hero {
    margin-bottom: 5px;
  }

  .trendingNow {
    @media only screen and (min-width: $DESKTOP) {
      background: $WHITE;
      box-shadow: 0px 1px 3px rgb(0 0 0 / 17%);
    }
  }
}
