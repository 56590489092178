$TABLET: 600px;
$DESKTOP: 1280px;

@mixin tablet {
  @media screen and (min-width: $TABLET) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $DESKTOP) {
    @content;
  }
}
